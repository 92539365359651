import React from 'react';
import { observer } from 'mobx-react-lite';
import CircularProgress from '@mui/material/CircularProgress';
import style from './Competencies.module.scss';
import { Box } from '@mui/material';
import { SubCategoriesType } from '../../../Service/Types/models';

type CompetenciesProps = {
	competence: SubCategoriesType;
};

const Competencies = ({ competence }: CompetenciesProps) => {
	const currPercent = competence.percent;
	return (
		<tr className={style['competencies']}>
			<td>
				<div className={style['competencies__header']}>
					<Box sx={{ position: 'relative', display: 'flex', alignItems: 'center' }}>
						<CircularProgress
							variant="determinate"
							sx={{
								color: '#F0F0F0',
							}}
							size={31}
							thickness={10}
							value={100}
						/>
						<CircularProgress
							variant="determinate"
							sx={{
								color: '#48484880',
								position: 'absolute',
								left: 0,
							}}
							size={31}
							thickness={10}
							value={currPercent}
						/>
					</Box>
					<div>
						<p className={style['competencies__textLabel']}>Уровень владения</p>
						<p className={style['competencies__header_textPrecent']}>{currPercent}%</p>
					</div>
				</div>
			</td>
			<td>
				<p className={style['competencies__textLabel']}>Подкатегория</p>
				<p className={style['competencies_text']}>{competence.subCategoryName}</p>
			</td>
			<td>
				<div className={style['competencies__compaction']}>
					<p className={style['competencies__textLabel']}>Компетенция</p>
					<p className={style['competencies_text']}>{competence.compaction ? competence.compaction : '-'}</p>
				</div>
			</td>
		</tr>
	);
};

export default observer(Competencies);

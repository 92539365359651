import React, { useEffect, useState } from 'react';
import style from './VerificationDiploma.module.scss';
import { ReactComponent as DoneBackgroundIco } from '../../../Assets/icons/doneBackground.svg';
import { CircularProgress, Link, Modal } from '@mui/material';
import ReportsStore from '../../../Store/ReportsStore/reports-store';
import { useNavigate } from 'react-router-dom';
import { observer } from 'mobx-react-lite';
import AuthStore from '../../../Store/AuthStore/auth-store';
import { animated, useSpring } from '@react-spring/web';

type VerificationDiplomaProps = {};

const VerificationDiploma = ({}: VerificationDiplomaProps) => {
	const { setStep } = AuthStore;
	const navigate = useNavigate();
	const [isOpenVerificationDiploma, setIsOpenDiploma] = useState(false);

	const { isLoadDashboardIfo } = ReportsStore;
	const isFailedLoad = isLoadDashboardIfo === 'error';
	const isProcessing = ['processing', 'ai1', 'ai2'].includes(isLoadDashboardIfo);
	const isDone = isLoadDashboardIfo === 'done';

	useEffect(() => {
		if (isDone) {
			setIsOpenDiploma(true);
			setTimeout(() => {
				navigate('/reports');
				setIsOpenDiploma(false);
				setStep(1);
			}, 2000);
		}

		if (isFailedLoad || isProcessing) {
			setIsOpenDiploma(true);
		}
	}, [isLoadDashboardIfo]);

	const props = useSpring({
		from: { opacity: 0, transform: 'translate3d(0,-20px,0)' },
		to: { opacity: 1, transform: 'translate3d(0,0px,0)' },
	});

	return (
		<Modal
			open={isOpenVerificationDiploma}
			className={style['verificationDiploma']}
			hideBackdrop={true}
			aria-labelledby="modal-modal-title"
			aria-describedby="modal-modal-description"
		>
			<animated.div style={props} className={style['verificationDiploma__container']}>
				{(isDone || isFailedLoad) && (
					<DoneBackgroundIco className={isFailedLoad ? style['verificationDiploma__container_icoGray'] : ''} />
				)}
				{isProcessing && <CircularProgress style={{ color: 'rgba(30,30,30,0.5)' }} size={59} />}
				<div>
					<p className={style['verificationDiploma__container_textHeader']}>
						{isFailedLoad ? 'Ошибка загрузки данных' : isDone && 'Данные успешно загружены'}
						{isProcessing && (
							<>
								Подгружаем данные<br />
								{isLoadDashboardIfo === 'processing'
									? 1
									: isLoadDashboardIfo === 'ai1'
										? 2
										: isLoadDashboardIfo === 'ai2'
											? 3
											: 0}{' '}
								из 3
							</>
						)}
					</p>
					<p className={style['verificationDiploma__container_text']}>
						{isFailedLoad && (
							<>
								Попробуйте обновить <br /> страницу или вернуться позже.
							</>
						)}
						{isDone && (
							<>
								Сейчас вы увидите свои<br /> компетенции
							</>
						)}
						{isProcessing && (
							<>
								Подожди несколько минут. Мы загрузим<br /> данные
							</>
						)}
					</p>
				</div>
				{isFailedLoad && (
					<Link
						fontSize={14}
						onClick={() => window.location.reload()}
						underline={'none'}
						color={'#484848'}
						href="#"
					>
						Назад
					</Link>
				)}
			</animated.div>
		</Modal>
	);
};

export default observer(VerificationDiploma);

import React, { useEffect } from 'react';
import style from './Reports.module.scss';
import { observer } from 'mobx-react-lite';
import reportsStore from '../../Store/ReportsStore/reports-store';
import Competencies from '../../Components/ReportsComponent/Competencies';
import Salary from '../../Components/ReportsComponent/Salary';
import DevRecommendations from '../../Components/ReportsComponent/DevRecommendations';
import { animated, easings, useTrail } from '@react-spring/web';
import { useNavigate } from 'react-router-dom';


const Reports = () => {
	const navigate = useNavigate();
	const { competencies, getDashboardInfo } = reportsStore;
	//const [expandedSubcategories, setExpandedSubcategories] = useState<{ [key: string]: boolean }>({});

	/*	const toggleSubcategory = (subcategoryId: string) => {
			setExpandedSubcategories(prevState => ({
				...prevState,
				[subcategoryId]: !prevState[subcategoryId],
			}));
		};*/
	useEffect(() => {
		const id = localStorage.getItem('idDiplom');
		if (id) {
			getDashboardInfo(id).catch(() => {
				navigate('/register')
			})
		}
	}, []);
	const trailCompetencies = useTrail(competencies.length, {
		from: { opacity: 0, transform: 'translate3d(0,20px,0)' },
		to: { opacity: 1, transform: 'translate3d(0,0px,0)' },
		config: {
			duration: 1000,
			easing: easings.easeInOutQuad,
		},
	});

	return (
		<div className={style['reports']}>
			<div className={style['reports__competencies']}>
				<div className={style['reports__competencies__container']}>
					{!!trailCompetencies && trailCompetencies.map((category, index) => {
						const dataCompetencies = competencies[index];
						const categoryId = `${dataCompetencies.categoryName}-${index}`;

						return (
							<animated.div style={category} className={style['reports__competencies__blockCategory']} key={categoryId}>
								<div className={style['reports__competencies__blockCategory__header']}>
									<p className={style['reports__competencies_text']}>{dataCompetencies.categoryName}</p>
									{/*	{
										dataCompetencies?.subCategories.length > 1 &&
										<a className={style['reports__toggleBtn']} onClick={() => toggleSubcategory(categoryId)}>
											{expandedSubcategories[categoryId] ? 'Свернуть все компетенции' : `Развернуть больше компетенций (${dataCompetencies?.subCategories.length - 1})`}
										</a>
									} //.slice(0, expandedSubcategories[categoryId] ? undefined : 1)*/}
								</div>
								<div className={style['reports__competencies__tableScroll']}>
									<table className={style['competenciesTable']}>
										<tbody>
										{dataCompetencies?.subCategories.map((subcategory: any, index: number) => {
											return (
												<Competencies key={`${subcategory.id}-${index}`} competence={subcategory} />
											);
										})}

										</tbody>
									</table>

								</div>
								{
									!dataCompetencies?.subCategories.length && <div className={style['competenciesTable__emptyBlock']}>
										На данный момент тут ничего нет.
									</div>
								}

							</animated.div>
						);
					})}
				</div>
			</div>
			<div className={style['reports__line']} />
			<div className={style['reports__blockRow']}>
				<Salary />
				<DevRecommendations />
			</div>
		</div>
	);
};

export default observer(Reports);


import React, { useEffect, useState } from 'react';
import styles from './Register.module.scss';
import ButtonUI from '../../Components/UI/ButtonUI';
import InputUI from '../../Components/UI/InputUI';
import Steps from '../../Components/Steps';
import { ReactComponent as ArrowBackIcon } from '../../Assets/icons/Arrow.svg';
import SelectAutoComplete from '../../Components/UI/SelectAutoComplete';
import DatePickerUI from '../../Components/UI/DatePickerUI';
import { IconButton, Link } from '@mui/material';
import AuthStore from '../../Store/AuthStore/auth-store';
import { observer } from 'mobx-react-lite';
import {
	FieldStepRegisterType,
	KeyFieldStepRegisterEnum,
	KeyFieldType,
	stepsRegister,
} from '../../Common/Constants/registerFields';
import rootStore from '../../Service/RootStore/root-store';
import VerificationDiploma from '../../Components/Modals/VerificationDiploma';
import { animated, useSpring } from '@react-spring/web';

const Register = () => {
	const {
		step,
		setStep,
		dataRegister,
		getAllUniversityProgram,
		setDataRegister,
		setUniversityProgram,
		getAllUniversity,
	} = AuthStore;
	const { AuthStoreService, ReportsStore } = rootStore;
	const { isLoadDashboardIfo } = ReportsStore;
	const currentStepData = stepsRegister[step];
	const isLoadingSendData = isLoadDashboardIfo === 'processing' || isLoadDashboardIfo === 'ai1' || isLoadDashboardIfo === 'ai2';
	const [errors, setErrors] = useState<any>({
		lastName: false,
		firstName: false,
		middleName: false,
		university: false,
		universityProgram: false,
		certificate: false,
		registryNumber: false,
		graduationDate: false,
	});
	useEffect(() => {
		getAllUniversity();
	}, []);
	const onErrorHandler = (name: KeyFieldStepRegisterEnum, value: boolean) => {
		setErrors({
			...errors,
			[name]: value,
		});
	};
	const onSkip = () => {
		if (step === 3) {
			return onSubmit();
		}
		setStep(step + 1);
	};

	const onValidationHandler = async () => {
		const isValidRegistryNumber = validateNumber(dataRegister.registryNumber);
		const isValidSeries = validateSeries(dataRegister.certificateSeries);
		if (!isValidRegistryNumber) {
			onErrorHandler(KeyFieldStepRegisterEnum.RegistryNumber, true);
		}
		if (!isValidSeries) {
			onErrorHandler(KeyFieldStepRegisterEnum.CertificateSeries, true);
		}

		if (isValidRegistryNumber && isValidSeries) {
			await onSubmit();
		}
	};
	const onSubmit = async () => {
		try {
			const data = await AuthStoreService.sendRegisterData(dataRegister);
			if (!!data) {
				if (!dataRegister?.registryNumber) {
					localStorage.setItem('diplomDontSend', '0');
				}
				if (dataRegister?.registryNumber) {
					localStorage.setItem('diplomDontSend', '1');
				}
				localStorage.setItem('idDiplom', data.id);
				localStorage.setItem('uid', data.uid);
				localStorage.setItem('pid', data.pid);
				await AuthStoreService.repeatUntil(data.id, ['error', 'done']);
			}
		} catch (e) {

		}
	};
	const onChangeHandler = (key: KeyFieldStepRegisterEnum, value: any) => {
		if(key === KeyFieldStepRegisterEnum.University) {
			setDataRegister(KeyFieldStepRegisterEnum.UniversityProgram, '')
			setDataRegister(KeyFieldStepRegisterEnum.idProgram, '')
			setUniversityProgram([])
		}

		onErrorHandler(key, false);
		setDataRegister(key, value);
	};
	const onGoBackStep = () => {
		if (step === 1) return;
		setStep(step - 1);
	};
	const onNextStep = async () => {
		if (step === 3) return onValidationHandler();
		setStep(step + 1);
	};

	const renderField = (stepFieldData: FieldStepRegisterType & {
		value: string,
		name: KeyFieldStepRegisterEnum,
		error: boolean,
		onChange: any,
	}) => {
		const { key, onChange, ...fieldProps } = stepFieldData || {};
		switch (stepFieldData.field) {
			case KeyFieldType.text: {
				return <InputUI className={styles['register__input']}
												{...fieldProps}
												onChange={(e => onChangeHandler(key, e.currentTarget.value))} />;
			}
			case KeyFieldType.autocomplete: {
				const onTouchElement = async (data: { id: string, name: string }) => {
					if (stepFieldData.name === KeyFieldStepRegisterEnum.University && !!data) {
						onChangeHandler(KeyFieldStepRegisterEnum.idUniversity, data.id);
						onChangeHandler(KeyFieldStepRegisterEnum.University, data.name);
						await getAllUniversityProgram(data.id);
						return;
					}
					if (stepFieldData.name === KeyFieldStepRegisterEnum.UniversityProgram && !!data) {
						onChangeHandler(KeyFieldStepRegisterEnum.idProgram, data.id);
						onChangeHandler(KeyFieldStepRegisterEnum.UniversityProgram, data.name);
						return;
					}
					if (!data) {
						if (stepFieldData.name === KeyFieldStepRegisterEnum.University) {
							onChangeHandler(KeyFieldStepRegisterEnum.idProgram, '');
							onChangeHandler(KeyFieldStepRegisterEnum.UniversityProgram, '');
							setUniversityProgram([]);
							return;
						}
						onChangeHandler(KeyFieldStepRegisterEnum.idUniversity, '');
						onChangeHandler(KeyFieldStepRegisterEnum.University, '');
					}
				};
				return <SelectAutoComplete onTouchElement={onTouchElement}
																	 onChange={(e => onChangeHandler(key, e))}
																	 className={styles['register__input']} {...fieldProps} />;
			}
			case KeyFieldType.datePicker: {
				return <DatePickerUI onChange={(value: string) => {
					onChangeHandler(key, value);
				}} labelDatepicker={stepFieldData.label} {...fieldProps} />;
			}
			default:
				return null;
		}
	};
	const disabledBtn = (step === 2 && (!dataRegister?.idUniversity || !dataRegister?.idProgram) || isLoadingSendData);
	const props = useSpring({
		from: { opacity: 0, transform: 'translate3d(0,-20px,0)' },
		to: { opacity: 1, transform: 'translate3d(0,0px,0)' },
	});

	return (
		<div className={styles['register']}>
			<div className={styles['register__container']}>
				<animated.div style={props} className={styles['register__form']}>
					<div className={styles['register__form__header']}>
						<IconButton onClick={onGoBackStep} disabled={step === 1}
												sx={{ width: 24, height: 24, padding: 0, opacity: step !== 1 ? 1 : 0 }}>
							<ArrowBackIcon />
						</IconButton>
						<div className={styles['register__form__header__blockText']}>
							<p className={styles['register__form__header_title']}>{currentStepData?.headerText}</p>
							<p className={styles['register__form__header_text']}>
								{currentStepData?.headerDescription}
							</p>
						</div>

					</div>
					<Steps />
					<div className={styles['register__form__input-block']}>
						{
							currentStepData.fields.map((field, index) => {
								return <div key={`${index}-${field.label}`}>
									{renderField({
										...field,
										value: dataRegister[field.key] || '',
										error: errors[field.key],
										onChange: onChangeHandler,
										name: field.key,
									})}
								</div>;
							})
						}
					</div>
					<div className={styles['register__form__footer']}>
						<ButtonUI isLoading={isLoadingSendData} disabled={disabledBtn} onClick={onNextStep}
											className={styles['register__btn']}
											variant="contained">
							{
								isLoadingSendData ? 'Проверяем ваш диплом...' : '	Далее'
							}
						</ButtonUI>

						{
							step === 3 && !isLoadingSendData &&
							<Link fontSize={14} onClick={onSkip} underline={'none'} color={'#484848'} href="#">Пропустить</Link>
						}
						{
							step === 3 && isLoadingSendData &&
							<Link fontSize={14} onClick={() => window.location.reload()} underline={'none'} color={'#484848'}
										href="#">Отменить</Link>
						}
					</div>
				</animated.div>
			</div>
			<VerificationDiploma />
		</div>
	);
};

export default observer(Register);

const validateSeries = (series: string) => {
	const regex = /^\d{6}$|^[А-Я]{2}$|^[А-Я]{3}$/;
	return regex.test(series);
};
const validateNumber = (value: string) => {
	const regex = /^\d{5,7}$/;
	return regex.test(value);
};
export enum KeyFieldStepRegisterEnum {
	LastName = 'lastName',
	FirstName = 'firstName',
	MiddleName = 'middleName',
	University = 'university',
	UniversityProgram = 'universityProgram',
	CertificateSeries = 'certificateSeries',
	RegistryNumber = 'registryNumber',
	GraduationDate = 'graduationDate',
	idUniversity = 'idUniversity',
	idProgram = 'idProgram',
}

export enum KeyFieldType {
	text = 'text',
	autocomplete = 'autocomplete',
	datePicker = 'datePicker',
}

export type StepsRegisterType = {
	[key: string]: StepRegisterType
}

export type StepRegisterType = {
	headerText: string,
	headerDescription: string
	fields: FieldStepRegisterType[]
}
export type FieldStepRegisterType = {
	key: KeyFieldStepRegisterEnum
	field: KeyFieldType
	required: boolean
	label: string
	errorMessage?: string
}

export const stepsRegister: StepsRegisterType = {
	'1': {
		headerText: 'Введите ФИО',
		headerDescription: 'Укажите ваши персональные\nданные',
		fields: [
			{ key: KeyFieldStepRegisterEnum.LastName, field: KeyFieldType.text, required: false, label: 'Фамилия' },
			{ key: KeyFieldStepRegisterEnum.FirstName, field: KeyFieldType.text, required: false, label: 'Имя' },
			{ key: KeyFieldStepRegisterEnum.MiddleName, field: KeyFieldType.text, required: false, label: 'Отчество' },
		],
	},
	'2': {
		headerText: 'Учебное заведение',
		headerDescription: 'Укажите данные о вашем учебном\nзаведении',
		fields: [
			{
				key: KeyFieldStepRegisterEnum.University,
				field: KeyFieldType.autocomplete,
				required: false,
				label: 'Название ВУЗа',
			},
			{
				key: KeyFieldStepRegisterEnum.UniversityProgram,
				field: KeyFieldType.autocomplete,
				required: false,
				label: 'Образовательная программа',
			},
		],
	},
	'3': {
		headerText: 'Диплом',
		headerDescription: 'Укажите серию и номер вашего\nдиплома',
		fields: [
			{ key: KeyFieldStepRegisterEnum.CertificateSeries, field: KeyFieldType.text, required: false, label: 'Серия диплома', errorMessage: 'Введите корректную серию диплома' },
			{
				key: KeyFieldStepRegisterEnum.RegistryNumber,
				field: KeyFieldType.text,
				required: false,
				label: 'Регистрационный номер',
				errorMessage: 'Введите корректный номер'
			},
			{
				key: KeyFieldStepRegisterEnum.GraduationDate,
				field: KeyFieldType.datePicker,
				required: false,
				label: 'Дата выдачи',
			},
		],
	},
};
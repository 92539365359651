import React from 'react';
import style from './Steps.module.scss';
import {ReactComponent as CheckIco} from '../../Assets/icons/Check.svg'
import AuthStore from '../../Store/AuthStore/auth-store';
import { observer } from 'mobx-react-lite';

type StepsProps = {

}
const Steps = ({ }: StepsProps) => {
	const {step} = AuthStore
	return (
		<div className={style['steps']}>
			{
				[1, 2, 3, 4].map((item, index) => {
					return <div
						className={`${style['steps__block-number']} ${style[`${step >= item ? 'steps__block-number_active' : 'steps__block-number_not-active'}`]}`}
						key={index}>
						<div className={style['steps__block-number_bg']}>
							{
								item === 4  ? <CheckIco /> : item
							}
						</div>

						{
							item !== 4 && <div className={style['steps__block-number_line']} />
						}
					</div>;
				})
			}
		</div>
	);
};

export default observer(Steps);
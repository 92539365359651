export enum LoadingEnum {
	initial = 'initial',
	fetching = 'fetching',
	success = 'success',
	error = 'error',
	loadingMore = 'loadingMore',
}

export type NotificationType = 'success' | 'info' | 'warning' | 'error';
export type StatusLoadDashboardInfoType = 'ready' | 'processing' | 'error' | 'done' | '' | 'ai1' | 'ai2'

export type DataRegisterType = {
	lastName: string
	firstName: string
	middleName: string
	university: string
	universityProgram: string
	certificateSeries: string
	registryNumber: string
	graduationDate: string
	idUniversity: string
	idProgram: string
}
export type SalaryType = {
	'min': number,
	'avg': number,
	'max': number,
	'cnt': number,
	'dsp': number,
	'nzd': number
}
export type CurrentCategoryType = {
	categoryName: string
	subCategories: SubCategoriesType[]
}
export type SubCategoriesType = {
	subCategoryName: string
	compaction: string
	percent: number
}

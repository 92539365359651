import { makeAutoObservable, runInAction } from 'mobx';
import { registerApi } from '../../Api/registerApi';
import { KeyFieldStepRegisterEnum } from '../../Common/Constants/registerFields';
import { DataRegisterType } from '../../Service/Types/models';
import { generateRandomNumberId } from '../../Common/utils';


const dataRegisterStart: DataRegisterType = {
	lastName: '',
	firstName: '',
	middleName: '',
	university: '',
	universityProgram: '',
	certificateSeries: '',
	registryNumber: '',
	graduationDate: '',
	idUniversity: '',
	idProgram: '',
};

export class AuthStore {
	isAuth: boolean | null = false;
	step: number = 1;
	dataRegister: DataRegisterType = dataRegisterStart;
	university: any = [];
	programUniversity: any = [];

	setDataRegister = (key: KeyFieldStepRegisterEnum, value: any) => {
		this.dataRegister[key] = value;
	};
	searchUniversity = async (search: string) => {
		const { data } = await registerApi.searchUniversity(search);
		runInAction(() => {
			this.university = data;
		});
	};
	getAllUniversity = async () => {
		const { data } = await registerApi.getAllUniversity();
		runInAction(() => {
			this.university = data;
		});
	};
	getAllUniversityProgram = async (idUniversity: string) => {
		const { data } = await registerApi.getAllUniversityProgram(idUniversity);
		this.setUniversityProgram(data);
	};
	setUniversityProgram = (data: any) => {
		this.programUniversity = data;
	};
	searchUniversityProgram = async (idUniversity: string, search: string) => {
		const { data } = await registerApi.searchUniversityProgram(idUniversity, search);
		runInAction(() => {
			this.programUniversity = data;
		});
	};
	sendDataRegister = async (payload: DataRegisterType) => {
		const { idUniversity, idProgram, universityProgram, university } = { ...payload };
		const userid = !payload.firstName.trim() && !payload.registryNumber.trim() ? 0 : generateRandomNumberId()
		console.log(userid);
		const dataSend = {
			userid,
			uid: payload.idUniversity,
			pid: payload.idProgram,
			...payload,
		};
		const { data } = await registerApi.sendData(dataSend);
		this.dataRegister = dataRegisterStart;
		return data;
	};

	setStep = (step: number) => {
		this.step = step;
	};

	setAuth = (auth: boolean) => {
		this.isAuth = auth;
	};

	constructor() {
		makeAutoObservable(this);
	}
}

export default new AuthStore();


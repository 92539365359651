import axios from 'axios';

export const API_URL = 'https://oracle-backend.clickcontent.ru/';
export const instance = axios.create({
	baseURL: API_URL
});

// Request interceptor for API calls
instance.interceptors.request.use(
	async (config) => {
		const token = localStorage.getItem('token');
		return config;
	},
	(error) => {
		Promise.reject(error);
	},
);

// Response interceptor for API calls
instance.interceptors.response.use(
	(response) => {
		return response;
	},
	async function(error) {
		const originalRequest = error.config;
		return Promise.reject(error);
	},
);

import './GlobalStyles/main.scss';
import React from 'react';
import ReactDOM from 'react-dom/client';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { Router } from './Router/Router';
import { Notification } from './Components/Notification/Notification';
// or @mui/lab/Adapter{Dayjs,Luxon,Moment} or any valid date-io adapter


const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);
root.render(
	<LocalizationProvider dateAdapter={AdapterDayjs}>
		<BrowserRouter>
			<Routes>
				<Route path="/*" element={<Router />} />
			</Routes>
			<Notification />
		</BrowserRouter>
	</LocalizationProvider>,
);

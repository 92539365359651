import React from 'react';
import dayjs, { Dayjs } from 'dayjs';
import { MobileDatePicker } from '@mui/x-date-pickers';
import InputUI from '../InputUI';
import InputAdornment from '@mui/material/InputAdornment';
import { IconButton } from '@mui/material';
import { ReactComponent as CalendarIcon } from '../../../Assets/icons/Calendar.svg';

type DatePickerProps = any & {
	labelDatepicker?: string
	name?: string
}
const DatePickerUI = React.forwardRef(({ labelDatepicker, name, onChange, ...rest }: DatePickerProps, ref) => {
	const [value, setValue] = React.useState<Dayjs | null>(null);
	const [open, setOpen] = React.useState(false);
	const handleOpen = () => setOpen(true);
	const handleClose = () => setOpen(false);

	return (
		<div>
			<MobileDatePicker
				ref={ref}
				name={name}
				open={open}
				onOpen={handleOpen}
				onClose={handleClose}
				label={labelDatepicker}
				sx={{
					'& .MuiFilledInput-root .MuiFilledInput-input': {
						fontSize: '14px',
					},
				}}
				slots={{
					textField: textFieldProps => <InputUI inputProps={{
						endAdornment: (
							<InputAdornment position="end">
								<IconButton edge="end" onClick={handleOpen}>
									<CalendarIcon />
								</IconButton>
							</InputAdornment>
						),

					}} {...rest} {...textFieldProps} />,
				}}
				{...rest}
				value={value}
				onChange={(e) => {
					onChange(dayjs(e).format('DD/MM/YYYY'));
				}}
				slotProps={{
					textField: {
						InputProps: {
							endAdornment: (
								<InputAdornment position="end">
									<CalendarIcon fontSize={'small'} />
								</InputAdornment>
							),
						},
					},
				}}
			/>
		</div>
	);
});

export default DatePickerUI;
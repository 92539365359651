import { CurrentCategoryType, SubCategoriesType } from '../Service/Types/models';

const getSubCategoryName = (str: string) => {
	const parts = str.split(';');
	return parts[0].trim();
};
const removeFirstValue = (str: string) => {
	const parts = str.split(';');
	parts.shift();
	return parts.join(';').trim();
};
const parseSubCategories = (str: string, subCategoryName: string) => {
	const parts = str.split(';').map(part => part.trim())
	let result: SubCategoriesType[] = [] as SubCategoriesType[]
	parts.forEach(part => {
		const [name, value] = part.split(':').map(subPart => subPart.trim()); // Разделяем имя и значение и удаляем пробелы
		if (name && value) {
			result.push({
				subCategoryName: subCategoryName,
				compaction: name,
				percent: Number(value) ?? 0,
			})
		}
	});

	return result;
};

export const parsedAi2DataHandler = (ai2: any) => {
	const parsedDataCategories = JSON.parse(ai2).data.split('$');
	let categories: CurrentCategoryType[] = [];
	parsedDataCategories.forEach((item: string) => {
		// Пропускаем пустые строки и строки без содержимого
		if (item.trim() === '') return;

		// Разбиваем строку на категории и подкатегории
		let categoryParts = item.split('^');
		let categoryName = categoryParts[0].replace(/[;\n]/g, '').trim();
		let subCategories: SubCategoriesType[] = [];

		// Обходим подкатегории, начиная с индекса 1 (первый элемент - это категория)
		for (let i = 1; i < categoryParts.length; i++) {
			let subCategoryItem = categoryParts[i].trim();

			let subCategoryName = getSubCategoryName(subCategoryItem);
			const subCategory = parseSubCategories(removeFirstValue(subCategoryItem), subCategoryName)

			subCategories = subCategories.concat(subCategory);

		}
		subCategories.sort((a, b) => b.percent - a.percent);
		categories.push({ categoryName, subCategories });
	});

	return categories;
};

export const parseDataAi3 = (str: string) => {
	const regex = /\d+\.\s+/;
	return str.replace(/[\n;]+/g, ' ').trim().split(regex).slice(1);
};

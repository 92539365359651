import { makeAutoObservable } from 'mobx';
import { registerApi } from '../../Api/registerApi';
import { CurrentCategoryType, SalaryType, StatusLoadDashboardInfoType } from '../../Service/Types/models';
import { parsedAi2DataHandler, parseDataAi3 } from '../../Common/parseDataAi';

export class ReportsStore {
	salary: SalaryType = {} as SalaryType;
	competencies: CurrentCategoryType[] = [] as CurrentCategoryType[];
	universityInfo = {
		universityName: '',
		program: '',
	};
	topJobsNames: string[] = [];
	isLoadDashboardIfo: StatusLoadDashboardInfoType = '';

	setIsLoadDashboardIfo = (status: StatusLoadDashboardInfoType) => {
		this.isLoadDashboardIfo = status;
	};
	setDashboardInfo = (data: any) => {
		this.competencies = parsedAi2DataHandler(data.ai2);
		this.universityInfo = {
			universityName: data.university,
			program: data.program,
		};
		this.topJobsNames = parseDataAi3(JSON.parse(data.ai3).data);

		this.salary = data.salary;
	};
	getDashboardInfo = async (id: string) => {
		try {
			const { data } = await registerApi.getDashboardInfo(id);
			this.setIsLoadDashboardIfo(data.status);
			if (data.status === 'done') {
				this.setDashboardInfo(data);
			}

			return data;
		} catch (error) {
			return false;
		}
	};

	constructor() {
		makeAutoObservable(this);
	}
}

export default new ReportsStore();


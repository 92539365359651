import React from 'react';
import { observer } from 'mobx-react-lite';
import style from './Salary.module.scss';
import reportsStore from '../../../Store/ReportsStore/reports-store';
import { animated, useTrail } from '@react-spring/web';

type SalaryProps = {}
const Salary = ({}: SalaryProps) => {
	const { salary, topJobsNames } = reportsStore;
	const trailJobsName = useTrail(topJobsNames.length, {
		from: { opacity: 0, transform: 'translate3d(0,20px,0)' },
		to: { opacity: 1, transform: 'translate3d(0,0px,0)' },
	});

	return (
		<div className={style['salary']}>
			<p className={style['salary__labelHeader']}>Уровень заработной платы</p>

			<div className={style['salary__blockRow']}>
				<div>
					<p className={style['salary__text']}>{`${salary?.min ?? 0} руб`} </p>
					<p className={style['salary__label']}>Мин. уровень дохода с вашми данными<br />
						по данным <a target={'_blank'} href={'https://hh.ru/'}>hh.ru</a></p>
				</div>
				<div>
					<p className={style['salary__text']}>{`>${salary?.avg ?? 0} руб`}</p>
					<p className={style['salary__label']}>Ср. уровень дохода с вашми данными<br />
						по данным <a target={'_blank'} href={'https://hh.ru/'}>hh.ru</a></p>
				</div>
			</div>
		<div className={style['salary__lobBlock']}>
			{trailJobsName.map((props, index) => (
				<animated.p key={index} style={props} className={style['salary__lobBlock_text']}>
					{topJobsNames[index]}
				</animated.p>
			))}
		</div>
		</div>
	);
};

export default observer(Salary);
import React from 'react';
import style from './breadcrumbs.module.scss';
import { ReactComponent as CaretIco } from '../../Assets/icons/caret.svg';
import DoneIcon from '@mui/icons-material/Done';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
const Breadcrumbs = () => {
	const hasDiplom = localStorage.getItem('diplomDontSend') === '1'
	return (
		<div className={style['breadcrumbs']}>
			<p>
				ФИО
			</p>
			<CaretIco />
			<p>
				Учебное заведение
			</p>
			<CaretIco />
			<p>Диплом</p>
			<CaretIco />
			<div className={style['breadcrumbs__active']}>
				{
					hasDiplom ? <>
						<p>Диплом валидирован</p>
						<DoneIcon style={{ color: 'white' }} fontSize={'small'} />
					</> : <>

						<p>Диплом не найден</p>
						<ErrorOutlineIcon style={{ color: 'white' }} fontSize={'small'} />
					</>
				}

			</div>
		</div>
	);
};

export default Breadcrumbs;
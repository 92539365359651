import React from 'react';
import { observer } from 'mobx-react-lite';
import style from './DevRecommendations.module.scss';
import LineTo, { SteppedLineTo } from 'react-lineto';

const DevRecommendations = () => {

	return (
		<div className={style['devRecommendations']}>
			<p className={style['devRecommendations__text']}>Рекомендации для развития</p>
			<div className={style['devRecommendations__stage-container']}>
				<div className={style['devRecommendations__blockRow']}>

					<div className={style['devRecommendations__stageBlock']}>
						<p className={style['devRecommendations__textLabel']}>Этап 1</p>
						<p className={style['devRecommendations__text']}>Возможные треки</p>
						<div
							className={`B1 ${style['devRecommendations__textBlock']} ${style['devRecommendations__textBlock_first']}`}>
							<p></p>
						</div>
					</div>

					<div className={style['devRecommendations__stageBlock']}>
						<p className={style['devRecommendations__textLabel']}>Этап 2</p>
						<p className={style['devRecommendations__text']}>Недостаток компетенций</p>
						<div className={`B2 ${style['devRecommendations__textBlock']}`}>
							<p></p>
						</div>
						<div className={`B3 ${style['devRecommendations__textBlock']}`}>
							<p></p>
						</div>
						<div className={`B4 ${style['devRecommendations__textBlock']}`}>
							<p></p>
						</div>
					</div>


					<div className={style['devRecommendations__stageBlock']}>
						<p className={style['devRecommendations__textLabel']}>Этап 3</p>
						<p className={style['devRecommendations__text']}>Способы получения</p>
						<div className={`C1 ${style['devRecommendations__textBlock']}`}>
							<p></p>
						</div>
						<div className={`C2 ${style['devRecommendations__textBlock']}`}>
							<p></p>
						</div>
						<div className={`C3 ${style['devRecommendations__textBlock']}`}>
							<p></p>
						</div>
						<div className={`C4 ${style['devRecommendations__textBlock']}`}>
							<p></p>
						</div>
					</div>
				</div>


				<LineTo className={style['devRecommendations__steppedLine']} from="B1" to="B2" />
				<SteppedLineTo className={style['devRecommendations__steppedLine']} delay={0} from="B1" to="B3"
											 orientation="h" />
				<SteppedLineTo className={style['devRecommendations__steppedLine']} delay={0} from="B1" to="B2"
											 orientation="h" />
				<SteppedLineTo className={style['devRecommendations__steppedLine']} delay={0} from="B1" to="B4"
											 orientation="h" />
				<SteppedLineTo className={style['devRecommendations__steppedLine']} delay={0} from="B2" to="C1"
											 orientation="h" />
				<SteppedLineTo className={style['devRecommendations__steppedLine']} delay={0} from="B3" to="C2"
											 orientation="h" />
				<SteppedLineTo className={style['devRecommendations__steppedLine']} delay={0} from="B4" to="C3"
											 orientation="h" />
				<SteppedLineTo className={style['devRecommendations__steppedLine']} delay={0} from="B2" to="C4"
											 orientation="h" />

			</div>
		</div>
	);
};

export default observer(DevRecommendations);

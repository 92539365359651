import React from 'react';
import { Button, ButtonProps, CircularProgress, circularProgressClasses } from '@mui/material';
import styles from './buttonUI.module.scss';
import cs from 'classnames';

type ButtonUiProps = ButtonProps & {
	children: React.ReactElement | string
	error?: boolean
	isLoading?: boolean
}
const ButtonUi = ({ children, error, className, isLoading, ...rest }: ButtonUiProps) => {
	const classNames = cs(styles['buttonUI'], { [styles['error']]: error }, className);
	return (
		<Button className={classNames} variant="contained" {...rest}>
			{
				isLoading && <CircularProgress thickness={7} style={{ color: 'rgba(72,72,72,0.47)' }} disableShrink variant="indeterminate" size={16} />
			}
			{children}
		</Button>
	);
};

export default ButtonUi;
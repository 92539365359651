import React from 'react';
import style from './MainHeader.module.scss';
import LetteredAvatar from 'react-lettered-avatar';
import { ReactComponent as LogoIcon } from '../../Assets/images/logo.svg';
import { NavLink } from 'react-router-dom';
import Breadcrumbs from '../Breadcrumbs';
import reportsStore from '../../Store/ReportsStore/reports-store';
import { observer } from 'mobx-react-lite';
import { animated, useSpring } from '@react-spring/web';

const headerLinks = [
	{
		name: 'Главная (скоро)',
		to: '/register',
	},
	{
		name: 'О нас (скоро)',
		to: '/',
	},
	{
		name: 'Отчеты',
		to: '/reports',
	},
	{
		name: 'Помощь (скоро)',
		to: '/',
	},
];
const MainHeader = () => {
	const props = useSpring({
		from: { opacity: 0, transform: 'translate3d(0,-20px,0)' },
		to: { opacity: 1, transform: 'translate3d(0,0px,0)' },
	});

	const { universityInfo } = reportsStore;
	const { setIsLoadDashboardIfo } = reportsStore;
	return (
		<div className={style['header']}>
			<div className={style['header__container']}>
				<LogoIcon />
				<div className={style['header__links-block']}>
					{
						headerLinks.map((link, index) => {
							return <NavLink
								key={link.name}
								onClick={() => setIsLoadDashboardIfo('')}
								className={({ isActive }) =>
									`${style['header__link']} ${
										isActive ? style['header__link_active'] : ""
									}`
								}
								to={link.to}>
								{link.name}
							</NavLink>;
						})
					}
				</div>
				<div>
					<LetteredAvatar
						name="Lettered Avatar"
						color={'#FFFFFF'}
						size={32}
						backgroundColor={'rgba(255,255,255,0.2)'}
					/>
				</div>
			</div>
			<div >
				<Breadcrumbs />
				<div className={style['header__descriptionBlock']}>
					<p className={style['header_textXL']}>Оценка профессиональных навыков</p>
					<animated.div style={props} className={style['header__universityInfoBlock']}>
						<p>{universityInfo.universityName}</p>{' '}
						<p>&nbsp;({universityInfo.program})</p>
					</animated.div>
				</div>
			</div>

		</div>
	);
};

export default observer(MainHeader)
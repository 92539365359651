import { instance } from './config';
import { AxiosResponse } from 'axios';

export const registerApi = {
	searchUniversity(search: string): Promise<AxiosResponse<any>> {
		return instance.get(`oracle/search/university`, {params: {search}});
	},
	getAllUniversity(): Promise<AxiosResponse<any>> {
		return instance.get(`oracle/search/university/all`);
	},
	getAllUniversityProgram(idUniversity: string): Promise<AxiosResponse<any>> {
		return instance.get(`oracle/search/program/${idUniversity}/all`);
	},
	searchUniversityProgram(idUniversity:string, search:string): Promise<AxiosResponse<any>> {
		return instance.get(`oracle/search/program/${idUniversity}`, {params: {search}});
	},
	sendData(data: any): Promise<AxiosResponse<any>> {
		return instance.post(`Oracle`, data);
	},
	getDashboardInfo(id: any): Promise<AxiosResponse<any>> {
		return instance.get(`Oracle/${id}`);
	},
};
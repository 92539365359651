import ReportProblemIcon from '@mui/icons-material/ReportProblem';
import { TextField } from '@mui/material';
import { TextFieldProps } from '@mui/material/TextField/TextField';
import cs from 'classnames';
import React from 'react';
import styles from './InputUI.module.scss';

export type InputUIProps = TextFieldProps & {
	label?: string;
	register?: any;
	error?: boolean;
	errorMessage?: string;
	name?: string;
}
const InputUI = React.forwardRef(({
																		error,
																		className,
																		errorMessage,
																		name,
																		...rest
																	}: InputUIProps, ref) => {

	const classNames = cs(styles['input'], { [styles['error']]: error }, className);

	return (
		<TextField
			name={name}
			inputRef={ref}
			size="small"
			variant="filled"
			fullWidth
			sx={{
				'& .MuiFilledInput-root .MuiFilledInput-input': {
					fontSize: '14px',
				},
			}}
			label={rest.placeholder}
			error={error}
			helperText={error && errorMessage}
			className={classNames}
			{...rest}
		/>
	);
});

export default InputUI;
import { Outlet, useNavigate } from 'react-router-dom';
import MainHeader from '../Components/MainHeader';
import React, { useEffect } from 'react';
import AuthStore from '../Store/AuthStore/auth-store';

const Protected = () => {
  const { isAuth } = AuthStore;
  const navigate = useNavigate();
  const userId = localStorage.getItem('idDiplom');

  useEffect(() => {
    if (userId) {
      navigate('/reports');
    } else {
      navigate('/register');
    }
  }, [userId, navigate]);

  return (
    <>
      <MainHeader />
      <main className='main'>
        <Outlet />
      </main>
    </>
  );
};

export default Protected;

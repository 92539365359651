import React from 'react';
import { Autocomplete } from '@mui/material';
import InputUI, { InputUIProps } from '../InputUI';
import style from './SelectAutoComplete.module.scss';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import { observer } from 'mobx-react-lite';
import AuthStore from '../../../Store/AuthStore/auth-store';
import { KeyFieldStepRegisterEnum } from '../../../Common/Constants/registerFields';

type SelectAutoCompleteProps = InputUIProps & {
	name?: KeyFieldStepRegisterEnum
	onTouchElement: (data: {id: string, name: string}) => void
}
const SelectAutoComplete = React.forwardRef(({ onChange, name,onTouchElement, ...rest }: SelectAutoCompleteProps, ref) => {
	const { university, programUniversity } = AuthStore;
	const currOptions = name === KeyFieldStepRegisterEnum.University ? university : programUniversity
	const onChangeHandler = (e: any) => {
		onChange?.(e.currentTarget.value)
	};
	return <Autocomplete
		disablePortal
		size="small"
		key={name}
		noOptionsText='Нет доступных вариантов'
		loadingText='Загрузка...'
		getOptionLabel={(item: any) => item?.name }
		getOptionKey={(item: any) => item?.id }
		fullWidth
		id="combo-box"
		className={style['select-autocomplete']}
		options={currOptions}
		onChange={(e, value) => {
			onTouchElement(value)
		}}
		sx={{
			'& .MuiFilledInput-root .MuiFilledInput-input': {
				padding: '4px 2px',
				fontSize: '14px',
			},
			'& .MuiFilledInput-root .MuiAutocomplete-endAdornment': {
				right: '10px',
			},
		}}
		popupIcon={<KeyboardArrowDownIcon style={{ color: '#AEAEAE' }} />}
		renderInput={(params) => {
			return <InputUI onChange={onChangeHandler} name={name} {...rest} {...params} />
		}}
	/>;
});

export default observer(SelectAutoComplete);